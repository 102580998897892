const https = require('https');

const FormData = require('form-data');
const idx = require('idx');
const { SocksProxyAgent } = require('socks-proxy-agent');
const axios = require('axios');

const { isEmpty } = require('../../common/helpers');
const Config = require('../config');
const { getCookie } = require('../../helpers/ApiHelper');

const extractUserEmail = (req) => {
  const shopperId = req.locals?.auth?.details?.shopperId
    ? req.locals?.auth?.details?.shopperId
    : req.locals?.info?.info_shopperId;
  const plid = req.locals?.auth?.details?.plid
    ? req.locals?.auth?.details?.plid
    : req.locals?.info?.info_plid;

  if (req.config.fake_data && req.config.fake_shopper_email) {
    return req.config.fake_shopper_email;
  }

  if (!shopperId || !plid) {
    return null;
  }

  let customerEmailSuffix = '';

  // @todo - cleanup this check
  if ([565123, 1010673].includes(plid)) {
    return decodeURIComponent(getCookie(req.headers.cookie, 'sucuri_idp'));
  }

  switch (plid) {
    case 1:
      customerEmailSuffix = '@godaddy.partner';
      break;
    case 495469:
    case 4500:
      customerEmailSuffix = '@mediatemple.partner';
      break;
    case 565123:
    case 1010673:
      customerEmailSuffix = '';
      break;

    default:
      customerEmailSuffix = '@godaddy-reseller.partner';
      break;
  }

  return shopperId + customerEmailSuffix;
};

const getApiProxyAgent = (req) => {
  if (Config.get().env === 'local' && !req.config.gogogo_proxy_endpoint) {
    req.logger.debug('the config value "gogogo_proxy_endpoint" is not set');
  }

  try {
    return Config.get().env === 'local'
      ? new SocksProxyAgent(req.config.gogogo_proxy_endpoint)
      : null;
  } catch (e) {
    req.logger.error(e);
    return null;
  }
};

const rejectUnauthorized = () =>
  !['local', 'dev', 'test', 'ote'].includes(Config.get().env);

/**
 * Get https agent with rejectUnauthorized
 * @param {Bool} shouldRejectUnauthorized rejectUnauthorized true|false
 * @returns {https.Agent} https.Agent
 */
const httpAgent = (shouldRejectUnauthorized = rejectUnauthorized()) => {
  return new https.Agent({
    rejectUnauthorized: shouldRejectUnauthorized,
  });
};

/**
 * Build a FormData object
 * @param {Object} data Fields data to be added
 * @returns {FormData} FormData object
 */
const serializeFormData = (data) => {
  const formData = new FormData();

  for (var key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      formData.append(key, data[key]);
    }
  }
  return formData;
};

/**
 * Create an object based on an array of objects using one of inner objects' fields
 * as the key for the new object.
 *
 * @param {Object} arrayOfObjects This is the an array of objects.
 * @param {String} keyString This is the field we want to use as key in the new object.
 *
 * @returns {Object} Returns newObject
 */
const collectionKeyBy = (arrayOfObjects, keyString) => {
  const newObject = {};

  if (!Array.isArray(arrayOfObjects)) {
    return newObject;
  }

  arrayOfObjects.forEach((obj) => {
    if (Object.prototype.hasOwnProperty.call(obj, keyString)) {
      newObject[obj[keyString]] = obj;
    }
  });

  return newObject;
};

/**
 * Parses a JWT token into an object
 * @param {string} token The JWT token
 * @returns {Object} An Object of the JWT contents
 */
const parseJWT = (token) => {
  if (!token) return false;

  try {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
  } catch (error) {
    return false;
  }
};

/**
 * Cleans Normalizes URL
 * Supports the following features to clean url.
 * 1. Removes multiple forward slashes and replace with single slash
 * @param {string} inputUrlString
 * @returns {string} Clean URL
 */
const normalizeUrl = (inputUrlString = '') => {
  let urlString = inputUrlString.trim();

  let urlObject = null;

  try {
    urlObject = new URL(urlString);
  } catch {
    throw Error('Invalid URL string');
  }

  if (urlObject.pathname) {
    // remove double slashes from string
    urlObject.pathname = urlObject.pathname.replace(/(\/\/+)/g, '/');
  }

  urlString = urlObject.toString();
  return urlString;
};

/**
 * Determines the API endpoint to use for monitoring requests.
 * If the SecUI flag is on, it returns the monitors API endpoint
 * otherwise, the products API endpoint.
 *
 * @param req
 * @param endpoint
 * @returns {string} The endpoint
 */
const getMonitorsApiURL = (req, endpoint) => {
  const defaultEndpoint = normalizeUrl(
    `${req.config.products_api}/v1/monitors${endpoint}`,
  );

  if (
    !idx(
      req,
      (_) => _.config.switchboard.secui_products_api_monitors_api_direct,
    )
  ) {
    return defaultEndpoint;
  }

  // e2s accounts for impersonations
  const { cid, e2s, e2s2s, s2s } = parseJWT(req.cookies.auth_idp);
  const endpointCid = cid || e2s?.cid || e2s2s?.cid || s2s?.cid;

  if (!endpointCid) return normalizeUrl(defaultEndpoint);

  return normalizeUrl(
    `${req.config.scanners_api}/customers/${endpointCid}${endpoint}`,
  );
};

/**
 * Determines the API endpoint to use for WAF requests.
 * If the SecUI flag is on, it returns the WAF 2.0 API endpoint
 * otherwise, the products API endpoint.
 *
 * @param req
 * @param endpoint
 * @returns {string} The endpoint
 */
const getWAFApiV2URL = (req, endpoint) => {
  const customerId = req.locals?.auth?.details?.customerId;
  return `${req.config.products_api}/v2/firewalls/customers/${customerId}${endpoint}`;
};

const parseErrorResponse = async (response) => {
  let responseData = null;
  try {
    responseData = await response.clone().json();
  } catch {
    responseData = await response.clone().text();
  }

  return responseData;
};

/**
 * Checks if customer has sites on legacy WAF
 *
 * @param {Express.Request} req The incoming request
 * @returns {Promise<boolean>} - true if customer has sites and false if no sites or an error occurred.
 */
const checkWAFLegacySites = async (req) => {
  const { FIREWALL_KEY: k, FIREWALL_SUPER_KEY: sk } = process.env;

  const data = {
    k,
    sk,
    s: extractUserEmail(req),
    a: 'domains_activation_status',
  };

  const query = new URLSearchParams({ v2: 1 });
  const apiUrl = `${req.config.waf_api_endpoint}?${query}`;

  const reason = 'Failed to load WAF1.0 sites for shopper.';

  try {
    const reqConfig = {
      httpsAgent: getApiProxyAgent(req),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const resp = await axios.post(apiUrl, { ...data }, reqConfig);

    //  status: 0 = SUCCESS
    if (resp.data?.status !== 0) {
      req.logger.info(reason);
      return false;
    }

    if (isEmpty(resp.data.output?.activation_status)) {
      req.logger.info('Shopper has 0 WAF1.0 sites.');
      return false;
    }

    return true;
  } catch (error) {
    req
      .log(__filename)
      .error(
        `Error checking WAF Legacy sites for shopper - ${error?.message}`,
        { req },
      );

    return false;
  }
};

module.exports = {
  extractUserEmail,
  getApiProxyAgent,
  httpAgent,
  rejectUnauthorized,
  serializeFormData,
  collectionKeyBy,
  parseJWT,
  getMonitorsApiURL,
  getWAFApiV2URL,
  normalizeUrl,
  parseErrorResponse,
  checkWAFLegacySites,
};
