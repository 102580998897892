// @ts-check
var configSingleton = {};

/**
 * Config exposes a getter and setter for config data that can be easily accessed
 * at any point in the application's lifecycle
 */
const Config = {
  /**
   * @param {object} d An object of values to apply to the singleton
   */
  set: (d) => {
    configSingleton = { ...d };
  },
  /**
   * @returns {object} the config singleton's values
   */
  get: () => {
    return { ...configSingleton };
  },
};

module.exports = Config;
